.App {
  text-align: center;
}
body{
  background-color: #f6f6f6 !important;
  font-family: "Inter", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
}

::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background: #2b356b; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #2b356b; /* Color on hover */
}

h1, h2{
  font-weight: 600 !important; /* Semibold */
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Inter", sans-serif !important;
}

.signin-set {
  margin: 5% 15% 5% 15%;
}

.login-img {
  text-align: center;
  margin-top: 10%;
}

html {
  scroll-behavior: smooth;
}

/* Styles for LTR direction */
body[dir="ltr"] .some-class {
  text-align: left;
  justify-content: start;
}

/* Styles for RTL direction */
body[dir="rtl"] .some-class {
  text-align: right;
  justify-content: end;
}

.dash-nav {
  display: flex;
  justify-content: center;
}
button.btn.dropdown-toggle.pe-0.text-capitalize, button#dropdownMenuButton, a.dropdown-toggle.text-decoration-none.ps-2.py-2{
  align-items: center;
    display: inline-flex;
}

.dash-nav ul {
  display: flex;
  align-items: center;
  padding: 0;
  width: 50%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1); */
  align-items: center;
  justify-content: space-around;
  /* background-color: #caefef;
  background-image: linear-gradient(180deg, #ffffff, #c4e3f3); */
  /* border-radius: 50px; */
}
.dropdown-item:focus, .dropdown-item:hover{
  color: #fff;
  border-radius: 5px;
}

.dash-nav ul li {
  color: #212529;
}

.stepimgs {
  display: flex;
  justify-content: center;
}

.paymethods {
  border: 1px solid #D0D1D2;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}

.discountimg {
  position: absolute;
  top: 5px;
  width: 20%;
  right: 5px;
}

.css-1q2h7u5, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  color: #838383 !important;
  /* font-weight: 600 !important; */
}

.dash-background {

  min-height: 600px;
}

.blling-sec .css-1lmjrw0,.blling-sec .css-1lmjrw0{
  border: unset !important;
}

.dashbgbottom {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  /* background-image: url('.././public/assets/BgGraphicbottom.png'); */
  /* background-image: url('.././public/assets/BgGraphicbottom.png'); */
  /* background: linear-gradient(to right, rgba(243, 243, 243, 0) 90%, rgba(243, 243, 243, 1) 100%), url('.././public/assets/Bg-middown.png'); */
  /* background: url('.././public/assets/dashBgdown.webp'); */
}

.dash-mob {
  display: flex;
  justify-content: space-between;
}

#price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain !important;
  /* background-image: url('.././public/assets/BgGraphicbottom.png'); */
  background:
    linear-gradient(to right, rgba(243, 243, 243, 0) 60%, rgba(243, 243, 243, 1) 100%),
    linear-gradient(to top, rgba(243, 243, 243, 0) 60%, #cce5f7 100%),
    /* url('.././public/assets/Bg-middown.png'); */
}

.dash-container {
  /* background-image: url('.././public/assets/BgGraphic11.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 600px;
}

.stepscontainer {
  display: flex;
  justify-content: end;
  background-image: linear-gradient(90deg, #ffffff, #e2f1fb),
    linear-gradient(to top right, rgba(255, 0, 0, 0) 50%, #e2f1fb 100%),
    linear-gradient(to top left, rgba(255, 0, 0, 0) 50%, #ffffff 100%);
}

.steps {
  margin: 60px 0 0 0;
  width: 90%;
}

.steps h4 {
  background: -webkit-linear-gradient(#78cdff, #2483d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.steps h5 {
  margin-bottom: 50px;
  background: -webkit-linear-gradient(#78cdff, #2483d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dash-container h2 {
  font-size: 60px;
  font-weight: 900;
  background: -webkit-linear-gradient(#78cdff, #2483d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dash-featues {
  margin: 50px 0 0px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgba(200, 227, 247)); */
  background-image: linear-gradient(to bottom left, rgba(255, 0, 0, 0) 50%, rgba(200, 227, 247) 100%),
    linear-gradient(to bottom right, rgba(255, 0, 0, 0) 50%, #ecf1f4 100%);

}

.dash-featues svg {
  margin: 0% !important;
}

.dash-featues h1 {
  font-size: 45px;
  font-weight: 900;
  background: -webkit-linear-gradient(#C8D4DF, #98ADC3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.maindash-nav {
  align-items: center;
}

.useraccount-setting {
  background-color: #ffffff;
  border: 1px solid #D0D1D2;
  border-radius: 10px;
  padding: 25px;
}

.userimgup img {
  cursor: pointer;
  width: 10%;
  margin: 15px;
  border-radius: 75px;
}

.planadd {
  border: 1px solid #D0D1D2;
  border-radius: 5px;
  padding: 25px;
  margin: 10px 0;
}
.planadd span{
  font-size: 14px;
}

.css-1wcaknn, .css-1wcaknn {
  padding: 32px 0 !important;
}

.useraccount-setting input {
  border-radius: 10px;
}
.header-top-social ul {
  display: flex;
  align-items: center;
  justify-content: end;
  list-style: none;
  margin-bottom: 0;
}

.dltcontainer {
  background-color: #27366d8c;
  border: 1px solid #D0D1D2;
  border-radius: 10px;
  padding: 15px 0 0 10px;
}

.billingContainer {
  background-color: #fff;
  border: 1px solid #D0D1D2;
  border-radius: 10px;
  padding: 25px;
}
.account-setting{
  background-color: #fff;
  border-radius: 10px;
}
.billingContainer h4 {
  border-bottom: 1px solid #D0D1D2;
  padding: 0 0 20px 0;
}

.msgbboxcont {
  height: 100%;
  overflow: scroll;
  background-color: #ffffff;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
}

.prexvbox {
  /* border: 1.5px solid #27366d; */
  /* border-radius: 16px; */
}

.dash-plan {
  padding: 20px;
  /* margin: 30px 60px; */
  width: 80% !important;
  border-radius: 20px;
  background-color: rgba(232, 232, 232, 0.4);
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.botprofileContainer {
  margin: 20px !important;
}

#contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us {
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dash-contact {
  padding: 50px;
  /* margin: 30px 60px; */
  width: 80% !important;
  border-radius: 20px;
  /* background: #ffffff; */
  background-image: linear-gradient(to right, #e1eaef, #a9d5f4);
  text-align: center;
}

.dash-contact h1 {
  margin-bottom: 60px;
  font-weight: 900;
  color: #2f8cda;
}

.dash-contact p {
  font-size: 20px;
  font-weight: 600;
  color: #a1a1aa;
}

.btn-google-signin {
  border-radius: 50px !important;
  padding: 2px !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.help-outer {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding: 25px;
}

.dash-mail {
  border-radius: 10px !important;
  margin: 0 !important;
  padding: 20px !important;
}

.css-1lmjrw0, .css-1lmjrw0{
  border: 1px solid #D0D1D2 !important;
  border-radius: 10px !important;
}

.dash-contact .form-control {
  padding: 25px !important;
  border-radius: 10px !important;
  margin: 25px 0;
}

.dash-footer {
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 50px 0;
}

.dropdown-toggle::after {
  margin-left: 1rem !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border: unset !important;
}

.mainswitch .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .mainswitch .css-1q2h7u5 {
  padding: 12px 50px !important;
  min-height: 42px !important;
}
/* 
.css-1lmjrw0, .css-1lmjrw0{
  height: 50px !important;
  overflow: hidden;
} */
.css-1lmjrw0, .css-1lmjrw0{
  padding: 3px  8px!important;
  height: 50px !important;
  overflow: hidden;
}
.css-1ujnqem-MuiTabs-root{
  min-height: 42px !important;
}

.newsletter {
  text-align: start;
}

.forgetform {
  margin: 25%;
}

.tapbar-text{
  width: 100%;
  color: #fff;
  background-color: #27366d;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  align-items: center;
}
.tapbar-text ul li a {
  color: #fff;
  font-size: 16px;
  position: relative;
  z-index: 1;
  padding-left: 10px;
  margin-left: 10px;
}

.interface {
  margin: 20px 0 !important;
}

.dash-nav .nav-link {
  padding: 10px 40px;
  font-weight: 500;
}

.for-mob {
  padding-left: 0px;
  padding-right: 0px;
}

.dash-footer p {
  color: #ffffff;
  font-weight: 600;
}

.copyrightbox {
  text-align: center;
}

.copyrightbox p {
  color: #ffffff;
  font-weight: 600;
}

.chatuserlist {
  max-height: 100px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
}

.userimg {
  cursor: pointer;
}

.userimg img {
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid;
}

.mixblend {
  width: 100%;
  mix-blend-mode: multiply;
}

.chatadduser {
  width: 100%;
  text-align: center;
}

.month{
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 600;
  color: #fff;
}

.chatadduser i {
  color: #fff;
  font-size: 25px;
  margin-top: 90px;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #27366d;
}

.compare {
  margin: 60px;
  padding: 30px;
  border: 1px solid grey;
  border-radius: 10px;
}

.table.comparetable {
  margin: 30px 0 60px 0;
}

table.table.comparetable td {
  padding: 1rem !important;
  width: 20%;
  /* text-align: center; */
}

button{
  font-size: 14px !important;
}

table.table.comparetable thead th {
  border-bottom: none !important;
  border-top: none !important;
}


.adcopyright {
  text-align: center;
  position: fixed !important;
  bottom: 5px;
}

.passicon {
  position: relative;
}

.passicon i {
  position: absolute;
  top: 50%;
  /* Center vertically */
  right: 15px;
  /* Adjust right space */
  transform: translateY(-50%);
  /* Center the icon vertically */
  cursor: pointer;
  /* Ensure the cursor shows as pointer */
}

.dash-plan h1,
.dash-plan h2 {
  /* font-weight: 600; */
  /* background-image: linear-gradient(180deg, #7cc5f4, #2172b0); */
  /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
}

.avtarbox {
  border: 1px solid #27366d;
  border-radius: 10px;
  box-shadow: 0 0 15px #27366d;
  min-height: 161px;
}

.modalprofile i {
  position: absolute;
  right: 40px;
  color: red;
}

.chatpointer {
  cursor: pointer;
}

.inner-plan ul {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0% 20%;
  padding: 30px;
  justify-content: space-around;
}

button.btn.btn-outline-dark {
  padding: 2px 8px !important;
}

.botprofile {
  width: 60%;
  margin-bottom: 15px;
}

.setuserpic {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.inner-plan ul li a {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
}

.feature-container {
  background: -webkit-linear-gradient(#83C0E9, #159eef);
  /* margin: 30px 60px; */
  width: 80%;
  margin-bottom: 0% !important;
  border-radius: 20px;
}

.payment-container {
  border: 1px solid;
  border-radius: 10px;
  margin: 10%;
}

.cardsummery {
  padding: 15px;
}

.outer-space .btn:hover {
  background-color: #0d6efd;
}

.payinner {
  border: 1px solid;
  border-radius: 10px;
  margin: 50px 15px;
}

.payheader {
  border-bottom: 1px solid;
  background-color: #dddddd;
  padding: 10px 15px;
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.dash-logo {
  width: 100%;
}

.inner-dash {
  padding: 30px 50px;
  text-align: start;
  color: #ffffff;
}

.inner-dash h2 {
  margin-top: 12px;
  font-weight: 700;
}

.col-8 {
  flex: 1;
}

.feature-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.feature-row span {
  flex: 1;
}

.feature-row span:first-child {
  text-align: left;
}

.feature-row span:last-child {
  text-align: right;
}

.plancontain span {
  font-size: 14px;
  font-weight: 500;
}

button.btn.btn-outline-primary.btn-light:hover {
  background: #27366d;
}

.plancontain {
  background: #ffffff;
  /* background: linear-gradient(0deg, #82ccff, #ffffff); */
  /* border: 1px solid #0098ff; */
  border-radius: 10px;
  padding: 15px 10px;
  position: relative;
}

.plancontain h4 {
  text-align: center;
  font-weight: 700;
  /* border-bottom: 2px solid #000; */
  /* border-radius: 50px; */
  padding: 10px 20px;
  margin-bottom: 1px;
}

.plancontain h3 {
  font-size: 35px;
  font-weight: 700;
}

.plancontain h6 {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  margin-bottom: 15px;
}

/* .planA {
  border: 3px solid #75e775;
  background: linear-gradient(135deg, #75e775, #ffffff); 
}

.planB {
  border: 3px solid #b2795e;
  background: linear-gradient(135deg, #b2795e, #ffffff); 
}

.planC {
  border: 3px solid #e5e4e2;
  background: linear-gradient(135deg, #b9b5ad, #ffffff);
}

.planD {
  border: 3px solid #d5ae65;
  background: linear-gradient(135deg, #d5ae65, #ffffff);
}

.planE {
  border: 3px solid #34a1eb;
  background: linear-gradient(135deg, #34a1eb, #ffffff);
} */


.green-icon {
  color: green;
  margin-right: 5px;
}

.dash-crawsel {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.dash-container h1 {
  font-weight: 900;
}

.login-form input,
.login-form button {
  border-radius: 50px;
}

.tableimg {
  width: 25px !important;
}

.signin {
  padding: 25px;
  border: 2px solid #dee2e6;
  border-radius: 25px;
}

.form-group label {
  margin: 10px 0px;
}

.user-login .form-control {
  padding: 10px;
  border-radius: 50px;
  margin: 25px 0;
}
.for-mob{
  max-height: 50rem;
}

.allrights{
  width: 45%;
}

.navmain ul{
  padding: 0;
}
.navmain h6{
  font-size: 14px !important;
}

.navmain {
  font-size: 14px;
  /* min-height: 44rem; */
  /* border-right: 1px solid #ebebeb; */
  margin: 0 35px;
}

.dropdown-toggle[aria-expanded="false"]::after {
  transform: rotate(270deg);
  /* Rotate the icon when expanded */
}
.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(0deg);
  /* Rotate the icon when expanded */
}

.btn-primary {
  font-size: 14px !important;
  color: #fff !important;
  background-color: #27366d !important;
  border-color: #27366d !important;
}

.comparepln {
  border: none !important;
}

.comparepln:hover {
  color: #ffffff !important;
  border: none !important;
  background-color: #27366d !important;
}

.custmtab{
  width: 50% !important;
  overflow: auto;
  scrollbar-width: none;
}
.sources-table .table-bordr th, .sources-table .table-bordr td {
  padding: 10px 30px;
  width: 334px !important;
}
.Prevsend-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27366d;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  padding: 8px 12px;
}
.css-188mx6n-MuiSlider-root , .css-1228x3t{
  color: #27366d !important;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: unset !important;
}
/* .btn:hover{
  background-color: #27366d !important;
} */

.interfacemsgbox {
  position: relative;
  top: 0px;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 10px 5px;
  border-left: 1.5px solid #27366d;
  border-right: 1.5px solid #27366d;
  /* border-top: 1px solid #dee2e6; */
  color: #2c7eb8;
}

.iframeinterfacemsgbox {
  position: relative;
  top: 0px;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 20px 5px;
  /* border-top: 1px solid #dee2e6; */
  color: #2c7eb8;
}

.interfacemsgbox input {
  border: none;
}

.interfacemsgbox.d-flex.justify-content-center {
  border-top: none;
  border: 1.5px solid #27366d;
  border-radius: 0 0 15px 15px;
  padding: 5px;
}

.headlogo{
  margin: 25px 35px;
}

.iframeinterfacemsgbox.d-flex.justify-content-center {
  /* border-radius: 0 0 20px 20px; */
  padding: 5px;
}

.setting-form .form-group label {
  margin: 10px 0px;
  font-weight: 600;
  color: #464255;
}

.setting-form .form-control {
  padding: 10px;
  border-radius: 10px;
  margin: 4px;
}

.securityspace {
  padding: 0 1rem !important;
}

.previewbox {
  position: sticky;
  top: 5rem;
  /* position: fixed;
  top: 275px;
  right: 125px;
  width: 22%; */
}

.forgetpass {
  margin: 20px 10px;
}

.forgetpass a {
  font-size: 17px;
  letter-spacing: 1px;
  text-decoration: unset;
}

.searchbar input:focus-visible,
.searchbartab input:focus-visible {
  outline: unset;
}

.icn-btn {
  background: unset;
  border: unset;
}

.user-login .signin-btn {
  width: 100%;
  border-radius: 50px;
  padding: 10px;
}

.google-signin {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 10px;
}

.orline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 25px 0px !important;
}

.logo-set {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerlogo-sec {
  width: 22% !important;
}

.logo-img {
  text-align: center;
  margin: 1% 1% 1% 20%;
}

button.btn.btn-outline-dark {
  background: #dfdfdf;
}

ul {
  list-style-type: none;
}
h5 strong{
  color: #4a545d;
  font-size: 16px;
}

li,
button#dropdownMenuButton {
  color: #4a545d;
  font-size: 14px !important;
}
h6{
  font-size: 12px !important;
}
.header-sec {
  position: fixed;
  width: 80%;
  z-index: 1000;
  align-items: center;
  display: flex;
  /* backdrop-filter: blur(1px); */
  justify-content: space-between;
  padding: 25px 50px 25px 50px !important;
  background-color: #f6f6f6cc;
}
/* .header-sec {
  display: flex;
  align-items: center;
  height: 74px;
  padding: 0 50px;
  margin-top: 25px;
  justify-content: space-between;
} */

.main-sec {
  padding: 0 50px;
  max-height: 50rem;
  overflow: scroll;
  margin-top: 100px;
  overflow-x: auto;
  scrollbar-width: none;
  scrollbar-color: #2b356b transparent;

}

.css-heg063-MuiTabs-flexContainer,
.css-k008qs {
  justify-content: space-between;
}

.headname {
  display: grid;
  align-items: center;
}

h4 strong{
  color: #424255;
  font-weight: 600;
  font-size: 16px;
}
h6 strong{
  color: #464255;
  font-weight: 600;
}
.headname h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #464255;
}

input#tentacles {
  padding: 0px;
  border: 1px solid #babacd;
  border-radius: 5px;
}


.searchbar input {
  padding: 10px;
  border: unset;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: #efefef;
  border-right: unset;
}

.react-tel-input .form-control{
  height: 58px !important;
}
.react-tel-input .selected-flag{
  height: 80% !important;
}

.useraccount-setting .form-floating>label{
  z-index: unset !important;
}

.searchbar button {
  padding: 10px;
  border: unset;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #efefef;
  border-left: unset;
}

.text-container {
  padding: 10px;
  min-height: 5rem;
  margin-bottom: 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.containers {
  min-height: 25rem;
  border: 1px solid #D0D1D2;
  border-radius: 10px;
}

.containertwo {
  height: auto;
  border: 2px solid #27366d;
  border-radius: 10px;
}

.copyright {
  display: grid;
  text-align: center;
  justify-content: center;
  position: fixed !important;
  bottom: 20px;
  left: 50px;
}

.upgradebox {
  display: flex;
  /* justify-content: center; */
}
@media (max-height: 699px) {
  .upgradebox {
    display: none;
  }
}

/* .upgradenow {
  border-radius: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 15rem;
  width: 12%;
  padding: 0px 25px;
} */
.upgradenow {
  align-items: center;
  background-image: url(../public/assets/navback.png);
  border-radius: 20px;
  color: #fff;
  display: flex;
  width: 30%;
  flex-direction: column;
  height: 15rem;
  justify-content: space-around;
  padding: 0 25px;
}
.upgradenow button{
  padding: 8px;
}

.addbot {
  background-color: #efefef !important;
  padding: 50px !important;
  border-radius: 60px !important;
}

.chat-container {
  background-color: white;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prev-head {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding: 0 12px;
}

.prev-head img {
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50px;
}

.preview {
  border: 1.5px solid #27366d;
  border-bottom: none; 
  border-radius: 15px 15px 0px 0;
  /* border-bottom: 1px solid #dee2e6; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #2b356b;
  text-align: center;
  padding: 12px 0;
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.45); */
}

.iframechatbot__box {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
}

.iframepreview {
  /* border-radius: 20px 20px 0px 0; */
  /* border-bottom: 1px solid #dee2e6; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #2b356b;
  text-align: center;
  padding: 12px 0;
  margin: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.45);
}

.preview button {
  color: #fff;
}

.outer-space {
  margin: 0 10px;
}

.outer-space button {
  padding: 8px;
  border-radius: 10px;
  /* border: 2px solid #27366d; */
}

.show-btn {
  border: 2px solid #27366d;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}

.Hide-Sources {
  display: none;
}

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* align-items: center; */
  margin: 30px 30px 0 30px;
}

.table-bordr tr {
  border-bottom: 1px solid #dee2e6;
}

.table-bordr th,
.table-bordr td {
  padding: 10px 30px;
  /* width: 5%; */
}

.table-bordr .wide-col {
  width: 15%;
  /* Adjust the width percentage as needed */
}

#myColor {
  width: 40px !important;
}

hr {
  background-color: #000000;
}

.input-container {
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.table-bordr {
  width: 100%;
  border: 1px solid #D0D1D2;
  background-color: #ffffff;
  border-radius: 10px;
  border-collapse: unset !important;
  /* text-align: center; */
}

.searchbartab input {
  padding: 10px;
  width: 50%;
  border: 1px solid #D0D1D2;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* background: #efefef; */
  border-right: unset;
}

.searchbartab button {
  padding: 10px;
  border: 1px solid #D0D1D2 !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #ffffff;
  border-left: unset;
}

.chat-content {
  height: 400px;
  border-left: 1.5px solid #27366d;
  border-right: 1.5px solid #27366d;
  padding: 1.5rem 0.5rem 0.5rem;
  overflow: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
  background-color: #f3f7f8;
  margin: 0;
}

.iframechat-content {
  padding: 1.5rem 0.5rem 0.5rem;
  overflow: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
  margin-top: 60px;
}

.chat-message {
  display: flex;
  justify-content: start;
  margin-bottom: 0.5rem;
  gap: 2.5rem;
  flex-direction: column;
}

.phoneCode input, .phoneCode{
  width: 120px !important;
  border-color: #dee2e6 !important;
}

.user-text-message {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 16px;
}

.bot-message .bot-text {
  background-color: #bdc3c7;
  color: #000000;
  padding: 1rem;
  border-radius: 0px 10px 10px 10px;
  font-size: 0.875rem;
  margin: 0;
}

.client-text-message {
  position: relative;
  display: flex;
  align-items: end;
  gap: 10px;
  padding-right: 16px;
  justify-content: end;
}

.user-text {
  background-color: #2b356b;
  color: #2b2b2b;
}

.user-message .user-text {
  color: #000000;
  padding: 1rem;
  border-radius: 10px 0px 10px 10px;
  font-size: 0.875rem;
  margin: 0;
}

.bot-message-container {
  /* color: #000000; */
  /* background-color: #2b356b; */
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
}

.bot-message-container img {
  border-radius: 5px;
}

.text-block input,
.text-block textarea {
  border-radius: 10px;
}

.text-block {
  margin: 0 45px;
  ;
}

.fetchall {
  display: grid;
  font-size: 14px;
}

.urlfieldinput {
  width: 70%;
}

.urlfield input {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  /* margin: 0 45px; */
}

.addmore {
  text-align: end;
  margin: 20px 40px;
}

.css-1q2h7u5:hover,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
  color: #27366d;
  /* background: #27366d;
  border-radius: 10px; */
}
.form-control:focus {
  border: 2px solid #27366d !important;
  box-shadow: unset !important;
}

.mainswitch .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.mainswitch .css-1q2h7u5.Mui-selected {
  color: #27366d !important;
  font-weight: 500;
  padding: 15px 45px;
  /* background: #27366d; */
  border-radius: 24px;
}
.css-10d9dml-MuiTabs-indicator, .css-7ozjwe{
  background-color: #27366d !important;
}

.mainswitch .css-1aquho2-MuiTabs-indicator,
span.MuiTabs-indicator.css-ttwr4n {
  background-color: #27366d;
  height: 3px !important;
}

.MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-j7qwjs {
  align-items: flex-start !important;
}

.receivemail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  /* border-top: 1px solid #dee2e6; */
  border-bottom: 1px solid #dee2e6;
}

.notification input {
  width: 70%;
  margin: 0 15px 0 0;
  border-radius: 10px;
}

.notification {
  display: flex;
  margin: 25px 0
}

.urldatafield input {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  width: 70%;
  margin: 0 45px;
}

.urlfield label {
  /* margin: 5px 45px; */
  font-size: 20px;
}

.gpt p {
  margin: 0 0 0 10px;
  padding: 10px;
  border-radius: 10px;
  color: #795be3;
  background: #eae6fe;
}

/* MUI CSS */
.css-10d9dml-MuiTabs-indicator,
.css-7ozjwe {
  left: 0% !important;
}

.css-35zqo5-MuiTabs-root,
.css-1vr8b6v {
  border: 0px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
button#simple-tab-1,
.css-1q2h7u5 {
  align-items: start !important;
  font-family: "Inter", sans-serif !important;
  text-transform: unset !important;

}

.css-19kzrtu {
  padding: 24px 0 !important;
}

.custom-padding .css-19kzrtu {
  padding: 0 !important;
}

.upload svg {
  margin-top: 80px !important;
}

.guZdik,
.dtlKip {
  display: block !important;
  min-width: unset !important;
  max-width: unset !important;
  width: 100% !important;
  height: 15rem !important;
  border: 1px solid #dee2e6 !important;
  padding: 8px 16px 8px 8px !important;
  border-radius: 10px !important;
}

.container {
  height: 35rem;
  border: 2px solid #27366d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chaticon {
  font-size: 15px;
  color: rgb(104, 112, 126);
  border: 1px solid rgb(104, 112, 126);
  border-radius: 5px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.usertext .aigenerate {
  background: #d1ecff !important;
  max-width: 80% !important;
  border: 1px solid #88ceff;
  border-radius: 10px;
  padding: 10px 15px;
}

.aitext {
  display: flex;
  justify-content: start;
  margin: 25px 25px 0 25px !important;
}

.aitext .aigenerate {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #dde4e9;
}

.aigenerate {
  border: 1px solid #ced4da;
  line-height: 1.3;
  border: 1px solid #d5d8db;
  text-wrap: wrap;
  font-family: inherit;
  font-size: 100%;
}

.react-multi-carousel-list {
  height: 550px;
  width: 80%;
}

.react-multi-carousel-dot button:hover:active,
.react-multi-carousel-dot--active button {
  background: #0498FC !important;
}

.react-multi-carousel-dot button {
  border-color: #0498FC !important;
  background: #ffffff;
}

.msgbox {
  display: flex;
  margin-top: 10px;
  padding: 30px 0;
  border-radius: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.usertext {
  display: flex;
  justify-content: end;
  margin: 25px 25px 0 25px !important;
}

.msgbox .form-control {
  width: calc(100% - 100px);
}

.jWkLDY,
.gwDiyc {
  display: grid !important;
  justify-content: center !important;
}

.jWkLDY .file-types,
.gwDiyc .file-types {
  max-width: 100% !important;
}

.custom-tabs .MuiTabs-indicator {
  margin-top: 4px !important;
  height: 40px !important;
}

.feature-container svg {
  margin-top: 0px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #27366d;
  display: flex;
  padding: 15px;
  flex-direction: row;
  justify-content: space-between;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #27366d !important;
}
.css-1q2h7u5.Mui-selected{
  color: #27366d !important;
}

/* MUI CSS END */
/* SWITCH */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #28C76F !important;
}

.navitems {
  display: flex;
  align-items: center;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* SWITCH */

/* Responsive */
@media only screen and (max-width: 1280px) {
  .userpro{
    margin-top: -22px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .dash-nav ul {
    width: 70% !important;
  }

  .navmain {
    padding: 0px 0 0 0px !important;
  }

  .setuserpic img {
    /* width: 25% !important; */
  }

  .dash-plan {
    width: 90% !important;
  }

  .main-sec {
    padding: 0 20px !important;
    margin-top: 100px;
  }
  
  .header-sec{
    padding: 25px 20px 0 20px !important;
  }

  .wrapper ul li:nth-child(odd) {
    float: left;
    width: 70% !important;
    font-size: 12px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
    text-align: left;
  }

  .dash-content {
    margin: 2rem 2rem 2rem 7rem !important;
  }

  .chats {
    margin-top: 20px !important;
  }

  .wrapper ul li:nth-child(even) {
    float: right;
    text-align: left;
    font-size: 12px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    margin-right: 0px !important;
    width: 70% !important;
    color: rgba(255, 255, 255, 0.9);
    background-color: #2a97f8;
  }

  .dash-content h1 {
    font-size: 50px !important;
    font-weight: 700;
    margin: 0 20px;
  }

  .urlfield input {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }

  .botprofile {
    width: 50%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1204px) {
  .main-sec {
    padding: 0 50px !important;
  }

.allrights{
  width: 20%;
}
  .upgradenow{
    width: 20%;
  }
  .outer-space button {
    padding: 2px;
    border-radius: 5px;
    color: #2b356b;
    border: 2px solid #2b356b;
  }

  .outer-space .containertwo,
  .sorcecontainerss {
    padding: 5px !important;
  }
}
ul#settingMenuone {
  margin-left: 28px;
}

@media only screen and (max-width: 1024px) {
  .headname {
    margin: 0px 20px 0px 10px;
  }
  .headlogo{
    margin: 25px;
  }
  ul#settingMenuone {
    margin-left: 20px !important;
  }
  
  .navmain{
    margin: 0 25px;
  }
  .navmain a{
    padding: 8px 10px !important;
  }
  .css-1lmjrw0, .css-1lmjrw0{
    padding: 4px !important;
  }

  .dash-content h1 {
    font-size: 25px !important;
    font-weight: 700 !important;
    margin: 0 20px !important;
  }

  .previewbox {
    position: unset !important;
    width: 100% !important;
  }

  .botprofile {
    width: 40%;
    margin-bottom: 10px;
  }


  .dash-nav ul {
    width: 100% !important;
  }

  .dash-nav .nav-link {
    padding: 10px 25px;
  }

  .dash-logo {
    width: 90%;
  }

  .main-sec {
    padding: 0 10px !important;
  }

  .sorcecontainer span {
    font-size: 12px;
  }

  .containertwo button {
    margin: 10px 0 !important;
    padding: 1px !important;
    font-size: 10px;
  }

  .containertwo h5,
  .containertwo span {
    font-size: 10px;
  }

  .sorcecontainerss {
    padding: 0% !important;
  }

  .outer-space {
    margin: 0;
  }

  .mainswitch .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
  .mainswitch .css-1q2h7u5.Mui-selected {
    font-weight: 500;
    padding: 15px;
  }

  .containertwo.p-3 {
    padding: 15px !important;
    margin: 0 10px !important;
  }

}

@media only screen and (min-width: 768px) {

  .deskdrop,
  .dash-mob,
  .deskslide,
  .maindashmob-nav {
    display: none !important;
  }

}


@media only screen and (max-width: 767px) {

  .input-group,
  .nav-mob,
  .mobslide,
  .maindash-nav {
    display: none !important;
  }
  .dash-content {
    margin: 2rem 2rem 2rem 2rem !important;
}

  .plancontain,
  .dash-plan {
    padding: 0;
    margin-bottom: 20px;
  }

  .botprofile {
    width: 40%;
    margin-bottom: 10 px;
  }

  .react-multi-carousel-list {
    height: 425px;
  }

  .mobdrop,
  .logo-img,
  .dash-nav {
    display: none !important;
  }

  /* Base styles */
  #price {
    padding: 20px;
  }

  /* .dash-plan {
    text-align: center;
    padding: 20px;
    margin: 30px 30px;
  } */

  .inner-plan ul {
    list-style-type: none;
    padding: 0;
  }

  .inner-plan li {
    margin: 10px 0;
  }

  .inner-plan a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .inner-plan ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inner-plan li {
    margin: 5px 0;
  }

  .inner-plan a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .inner-plan a {
    font-size: 14px;
  }

  #price {
    padding-top: 50px;
  }

  #price h2 {
    font-size: 14px;
  }
}

/* .dash-plan {
    text-align: center;
    padding: 10px;
    margin: 20px 10px;
  } */


.dash-nav .nav-link {
  padding: 15px;
}

.feature-container {
  margin: 30px 10px;
}

.dash-logo {
  width: 40%;
}

.tableimg {
  width: 20px !important;
}

.containers {
  min-height: 14rem !important;
  margin-bottom: 10px;
  background-color: #ffffff;
}

/* 
  button.btn.btn-primary.p-2 {
    padding: 5px !important;
    font-size: 10px;
  } */

/* 
  .headerlogo-sec img {
    margin: 0px 10px !important;
    width: 100% !important;
  } */

@media only screen and (max-width: 485px) {
  .signin-set {
    margin: 5% 8% 5% 8%;
}
}
@media only screen and (max-width: 425px) {

  .css-heg063-MuiTabs-flexContainer,
  .css-k008qs {
    overflow: auto;
    scrollbar-width: none;
  }
  .login-img {
    margin-top: 5%;
  }
  button.btn.backarrow.mx-5.my-3 {
    z-index: 9999;
    margin-left: 20px !important;
}

  .outer-space {
    margin: 10px;
  }
  .mainswitch .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .mainswitch .css-1q2h7u5 {
    padding: 10px 25px !important;
    min-height: 40px !important;
  }
  .css-1lmjrw0, .css-1lmjrw0{
    height: 50px !important;
    overflow: hidden;
  }
  .text-block{
    margin: 10px !important;
  }
  .outer-space button{
    padding: 8px !important;
    margin: 10px 0;
  }

  .dash-content {
    margin: 2rem !important;
  }

  .col-12.col-lg-5.col-xl-5.p-0.text-end {
    margin-top: 2rem !important;
  }

  .inner-dash {
    padding: 10px;
  }

  .dash-content h1 {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin: 0px 10px !important;
  }

  .wrapper ul li:nth-child(odd) {
    float: left;
    width: 80% !important;
    margin-left: 0px !important;
    text-align: left;
  }

  .wrapper ul li {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 20px;
    padding: 10px !important;
    background-color: #d9d9d9;
    border-radius: 25px;
    position: relative;
  }

  .wrapper ul li {
    font-size: 14px !important;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 10px !important;
    background-color: #d9d9d9;
    border-radius: 25px;
    position: relative;
  }

  .wrapper ul li:nth-child(even) {
    float: right;
    text-align: left;
    margin-right: 0px !important;
    width: 80% !important;
    color: rgba(255, 255, 255, 0.9);
    background-color: #2a97f8;
  }

  .dash-contact h1 {
    font-size: 30px;
    font-weight: 900;
    color: #a1a1aa;
  }

  .botprofile {
    width: 25%;
    margin-bottom: 10px;
  }

  .dash-contact p {
    font-size: 15px;
    font-weight: 600;
    color: #a1a1aa;
  }

  .dash-contact {
    border: 1px solid #000000;
    padding: 30px 20px;
    margin: 30px 10px;
    border-radius: 20px;
    background: #ffffff;
    text-align: center;
  }

  .signin-set,
  .forgetform {
    margin: 50px 0 !important;
  }

  .dash-nav .nav-link {
    padding: 10px;
  }

  .for-mob {
    padding: 5px 15px !important;
  }

  .dash-logo {
    width: 50%;
  }

  .dash-container {
    min-height: 350px !important;
  }

  .dash-background {
    min-height: 0% !important;
  }

  .dash-container h1 {
    font-size: 35px;
  }

  .dash-container h1 {
    font-size: 20px;
  }

  .dash-container h2 {
    font-size: 30px;
  }

  .urlfield {
    text-align: center;
  }

  .setuserpic {
    display: grid;
    margin: 35px 0;
  }

  #myColor {
    width: 22% !important;
  }

  .profile input[type="file"] {
    font-size: 10px;
  }

  .securityspace,
  .securityspace .setting-form {
    padding: 0 !important;
  }
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  /* Enables horizontal scrolling */
}

.notifybox label h4 {
  font-size: 16px;
}

.table-bordr {
  width: 100%;
  border-collapse: collapse;
}

.table-bordr th,
.table-bordr td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  /* Prevents text wrapping */
}

.table-bordr th {
  /* background-color: #f4f4f4; */
  /* Optional: Adds background color to headers */
}

.table-bordr td {
  border-bottom: 1px solid #ddd;
  /* Optional: Adds a bottom border to cells */
}

.table-bordr .wide-col {
  width: 50%;
  overflow: auto;
  scrollbar-width: none;
  /* Adjust width for the first column if needed */
}

.icn-btn img {
  width: 100%;
}

@media (max-width: 768px) {

  .table-bordr th,
  .table-bordr td {
    font-size: 12px;
    /* Adjust font size for smaller screens */
  }
  .header-sec{
    padding: 0 !important;
  }
}

/* Loader Css */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#2b356b) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}
/* .loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #2b356b;
  box-shadow:
    calc(1*var(--d)) calc(0*var(--d)) 0 0,
    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d)) calc(1*var(--d)) 0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d)) calc(-1*var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn)
  }
} */

#outerLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .loader {
  width: 90px;
  height: 14px;
  background: radial-gradient(circle closest-side, #000 92%, #0000) calc(100%/3) 0/calc(100%/4) 100%;
  animation: l2 0.5s infinite linear;
} */

@keyframes l2 {
  100% {
    background-position: 0 0
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  text-align: center;
  animation: blink 1.25s infinite;
}

/* HTML: <div class="loader"></div> */
.ai_thinking {
  width: 60px;
  aspect-ratio: 5;
  --_g: no-repeat radial-gradient(circle closest-side, #27366d 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
  }

  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
  }

  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
  }
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #27366d !important;
  min-width: 85px !important;
}

.backarrow {
  position: absolute;
  top: 0;
  left: 0;
}

.bg-purple {
  background-color: #27366d;
}

.input-group-text {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
}

.containerpay {
  /* height: 50rem; */
  border: 2px solid #27366d;
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.col-md-6.bg-purple.text-white.p-5,
.col-md-6.bg-light.p-5 {
  border-radius: 5px;
}

.select-dropdown {
  height: 20%;
  overflow: scroll;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #2b356b transparent;
}

.wrapper ul li:nth-child(odd) {
  float: left;
  width: 51%;
  /* margin-left: 80px; */
  text-align: left;
}

.wrapper ul li:nth-child(even) {
  float: right;
  text-align: left;
  /* margin-right: 20px; */
  width: 51%;
  color: rgba(255, 255, 255, 0.9);
  background-color: #2a97f8;
}

.wrapper ul li:after {
  width: 0;
  height: 0;
  /* top: 15px; */
  border-top: 15px solid rgba(255, 255, 255, 0.65);
}

.dash-content {
  margin: 2rem 2rem 2rem 10rem;
}

.dash-content h1 {
  font-size: 50px;
  font-weight: 900;
  margin: 2rem 0;
  background-image: linear-gradient(180deg, #7cc5f4, #2172b0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wrapper ul li {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #d9d9d9;
  border-radius: 25px;
  position: relative;
  /* Ensure relative positioning for pseudo-elements */
}

.wrapper ul li:nth-child(odd):after {
  left: 30px;
  /* Move the triangle to the left */
  bottom: -26px;
  /* Keep this as is to adjust height */
  border-right: 35px solid transparent;
  /* Change border-left to border-right */
  border-top: 27px solid #d9d9d9;
  /* Keep this as is */
  content: '';
  position: absolute;
}

.wrapper ul li:nth-child(even):after {
  right: 35px;
  bottom: -26px;
  /* Position it at the bottom */
  border-left: 35px solid transparent;
  border-top: 27px solid #27366d;
  /* Same as odd, adjust as needed */
  content: '';
  position: absolute;
}

.wrapper ul {
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
  list-style-type: none;
  /* Remove bullet points */
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #27366d !important;
}

.dashlogin .btn-outline-primary , a:hover{
  color: #27366d !important;
}
.dashlogin .btn-outline-primary:hover {
  color: #fff !important;
  background: #27366d !important;
}

.btn:disabled {
  background: white !important;
  color: #27366d !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
/* 
.monthly{
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: #e8e8e866;
  border-radius: 20px;
  padding: 0 20px;
} */
.monthly button{
  font-size: 18px;
  font-weight: 600;
}
.monthly span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator{
    left: 154.141px;
    width: 154.141px;
}
.monthly .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .monthly .css-1q2h7u5{
    width: 50%;
    display: flow;
    text-align: center;
    justify-content: center;
}
/* 
.monthly{
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: #e8e8e866;
  border-radius: 20px;
  padding: 0 20px;
} */
.dashmonthly button{
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600;
}
.dashmonthly span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator{
    left: 154.141px;
    width: 154.141px;
}
.dashmonthly .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .dashmonthly .css-1q2h7u5{
    width: 33.33%;
    display: flow;
    text-align: center;
    justify-content: center;
    border-bottom: 3px solid #fff;
}
.dashmonthly .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #fff !important;
}
.dashmonthly .css-1gsv261, .dashmonthly .css-1gsv261{
  border-bottom: none !important;
}

.table-url-process th, .table-url-process td{
  padding: 10px 20px !important;
}

/* openheader sidebar */
.maindashmob-nav {
  /* background-color: #f8f9fa; */
  padding: 10px;
}

.logo {
  max-width: 100%;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100%;
  backdrop-filter: blur(10px);
  color: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* transition: transform 0.3s ease; */
  transition: right 0.3s ease; /* Transition effect */
}

.close-btn {
  background: transparent;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  /* font-weight: 700; */
  padding: 10px 0;
  color: #000000;
  cursor: pointer;
}

.sidebar-menu li:hover {
  background-color: #495057;
}

/* openheader sidebar */


/* Dashboard */
.banner-area {
    /* background: #f9f8f4; */
    background: #f9f8f4;
    position: relative;
    z-index: 1;
  }
  .container.custom-container-1 {
    border: none;
  }
  @media (min-width: 1700px) {
    .container.custom-container-1 {
        max-width: 1630px;
        margin-left: auto;
        margin-right: auto;
    }
  }
  .banner-area .container {
    padding-right: 15px;
    padding-left: 15px;
    height: unset;
    border: none;
  }
  .banner-single {
    padding-top: 150px;
    padding-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 600px;
    gap: 40px;
  }
  .banner-content {
    width: 720px;
    flex-shrink: 0;
  }
  .banner-content-subtitle {
    border-radius: 50px;
    background: rgb(255 0 0 / 6%);
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.2);
    display: inline-block;
    color: #F15342;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 30px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 12px;
  }
  .banner-content-title {
    color: #1B2A52;
    font-size: 70px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -2.1px;
    margin-bottom: 20px;
  } 
  .banner-content-btn {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .theme-btn {
    border-radius: 50px;
    background: #27366d;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    height: 46px;
    line-height: 45px;
    padding: 0 25px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .banner-content-btn span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1B2A52;
    font-size: 14px;
    font-weight: 500;
  }
  .banner-content {
    width: 720px;
    flex-shrink: 0;
  }
  .banner-img {
    max-width: 644px;
  }
  .banner-shape-1 {
    position: absolute;
    left: 75px;
    top: 195px;
    top: 21%;
    z-index: -1;
  }
  .banner-shape-2 {
    position: absolute;
    left: calc(37% + 11px);
    transform: translateX(-50%);
    top: 180px;
    z-index: -1;
  }
  .banner-shape-3 {
    position: absolute;
    left: calc(44% - 5px);
    transform: translateX(-50%);
    bottom: 120px;
    z-index: -1;
  }
  .banner-shape-4 {
    position: absolute;
    left: calc(51% - 5px);
    transform: translateX(-50%);
    bottom: 395px;
    z-index: -1;
  }
  .banner-shape-5 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .choose-area .container {
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    height: unset;
  }
  .section-subtitle {
    border-radius: 50px;
    background: rgb(255 0 0 / 6%);
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.2);
    display: inline-block;
    color: #f15342;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 25px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 11px;
  }
  .section-title {
    color: #1b2a52;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: -1.76px;
  }
  .choose-item {
    border-radius: 10px;
    border: 1px solid rgba(27, 42, 82, 0.07);
    background: #FFF;
    padding: 40px 35px 35px;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .choose-item-count {
    position: absolute;
    right: 45px;
    top: 44px;
    color: rgb(255 0 0 / 6%);
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -1.2px;
    display: block;
    transform: rotate(-15deg);
  }
  .choose-item-img {
    margin-bottom: 34px;
  }
  .resized-image {
    width: 70px;
    height: 70px;
  }
  .choose-item-content-title {
    color: #1B2A52;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.6px;
    margin-bottom: 15px;
  }
  .choose-item-content-title a {
    color: #1B2A52;
    text-decoration: none;
    transition: all 0.3s linear 0s;
  }
  .choose-item-content p {
    color: #6A738B;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
  }
  .choose-item-content-btn {
    color: #1B2A52;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .choose-item-content-btn i {
    margin-top: 2px;
  }
  .choose-area .container{
    height: unset;
    border: none;
  }
  .pt-140 {
    padding-top: 140px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .choose-item.mb-30 {
    min-height: 400px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .choose-area{
    background-color: #f8eadc;
  }
  .feature-area {
    background-color: #f8eadc;
  }
  .pb-110 {
    padding-bottom: 110px;
  }
  .pt-110 {
    padding-top: 110px;
  }
  .feature-area .container {
    padding-right: 15px;
    padding-left: 15px;
    height: unset;
    border: none;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .section-subtitle {
    border-radius: 50px;
    background: rgb(255 0 0 / 6%);
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.2);
    display: inline-block;
    color: #f15342;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 25px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 11px;
  }
  .section-title {
    color: #1b2a52;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: -1.76px;
  }
  .feature-top {
    display: flex;
    gap: 105px;
  }
  .feature-item {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid rgba(27, 42, 82, 0.07);
    background-color: #FFF;
    padding: 30px 30px;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .feature-item-title {
    color: #1B2A52;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .feature-item p {
    color: #6A738B;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .feature-item-1::after {
    position: absolute;
    right: -84px;
    top: 50%;
    transform: translateY(-50%);
    content: url("../public/assets/images/feature/shape-4.png");
  }
  .feature-item-2::after {
    position: absolute;
    right: -84px;
    top: 50%;
    transform: translateY(-50%);
    content: url("../public/assets/images/feature/shape-4.png");
  }
  .feature-left-img {
    margin-right: 30px;
  }
  .feature-left-img img {
    width: 100%;
  }
  .feature-left-img-visible:first-of-type {
    display: block;
  }
  .feature-left-img-hide{
    display: none;
  }
  .feature-right-img {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: end;
  }
  .feature-shape-1 {
    position: absolute;
    right: -45px;
    top: 75px;
  }
  .feature-shape-2 {
    position: absolute;
    left: 105px;
    top: -15px;
  }
  .feature-shape-3 {
    position: absolute;
    left: 25px;
    top: 135px;
  }
  .apps-area {
    /* background: #f9f8f4; */
    background: #f9f8f4;
    position: relative;
    z-index: 1;
  }
  .pb-140 {
    padding-bottom: 140px;
  }
  .apps-area .container {
    padding-right: 15px;
    padding-left: 15px;
    height: unset;
    border: none;
  }
  .apps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    column-gap: 45px;
    flex-wrap: wrap;
  }
  .apps-icon-item:nth-child(even) {
    margin-bottom: 50px;
  }
  .apps-content {
    text-align: center;
  }
  .apps-content h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -1.76px;
    margin-bottom: 15px;
  }
  .apps-content p {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 45px;
  }
  .theme-btn {
    border-radius: 50px;
    background: #27366d;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    height: 46px;
    line-height: 45px;
    padding: 0 25px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .apps-shape-1 {
    position: absolute;
    left: 5%;
    top: 40%;
  }
  .apps-shape-2 {
    position: absolute;
    left: calc(22% + 8px);
    bottom: 165px;
  }
  .apps-shape-4 {
    position: absolute;
    bottom: 185px;
    right: calc(21% - 12px);
  }
  .apps-shape-5 {
    position: absolute;
    right: calc(9% + 7px);
    top: 50%;
    transform: translateY(-50%);
  }
  .apps-shape-6 {
    position: absolute;
    right: calc(13% + 3px);
    bottom: 115px;
  }
  .price-area .container {
    padding-right: 15px;
    padding-left: 15px;
    height: unset;
    border: none;
  }
  .mb-45 {
    margin-bottom: 45px;
  }
  .section-subtitle {
    border-radius: 50px;
    background: rgb(255 0 0 / 6%);
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.2);
    display: inline-block;
    color: #f15342;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 25px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 11px;
  }
  .section-title {
    color: #1b2a52;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: -1.76px;
  }
  .price-switch-wrapper {
    text-align: center;
    margin-bottom: 40px;
  }
  .price-tab .toggler {
    transition: 0.2s;
    cursor: pointer;
    line-height: 12px;
    text-align: center;
    color: #7F879E;
    font-size: 15px;
    font-weight: 600;
  }
  .price-tab .toggle {
    position: relative;
    width: 70px;
    height: 34px;
    border-radius: 100px;
    background-color: #f14c47;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
  }
  .price-tab .tp-check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
  }
  .price-tab .tp-check:checked ~ .switchdash {
    right: 5px;
    left: 57.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0.08s, 0s;
  }
  .price-tab .switchdash {
    position: absolute;
    left: 5px;
    top: 4px;
    bottom: 4px;
    right: 57.5%;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, 0.08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  .price-tab .toggle, .price-tab .toggler {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .price-tab .toggler--is-active {
    color: #1b2a52;
  }
  .price-switch-wrapper {
    text-align: center;
    margin-bottom: 40px;
  }
  .price-item {
    min-height: 700px;
  }
  .price-item {
    border-radius: 14px;
    /* background: #f9f8f4; */
    background: #f9f8f4;
    padding: 0 24px 42px;
    margin-bottom: 30px;
  }
  .price-item-head {
    text-align: center;
    padding-top: 40px;
    margin-bottom: 30px;
  }
  .price-item-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .price-item-amount-title {
    color: #1B2A52;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -1.6px;
    margin-bottom: 0px;
  }
  .price-item p {
    color: #6A738B;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    margin-bottom: 15px;
  }
  .price-item-1 p {
    margin-bottom: 75px;
  }
  .price-item-list {
    list-style: none;
    padding: 0;
  }
  .price-item-list li {
    color: #1B2A52;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 20px;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
  }
  .price-item-list li::before {
    position: absolute;
    left: 0;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: linear-gradient(180deg, #1FDB00 0%, #099B18 93.72%);
    top: 7px;
  }
  .price-item-option {
    display: flex;
    justify-content: center;
  }
  .price-item-option span {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 0px 0px 8px 8px;
    background: #F15342;
    padding: 0 12px;
    height: 24px;
    line-height: 24px;
  }
  .price-item-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  .price-item-btn {
    border-radius: 8px;
    background: #FFF;
    text-decoration: none;
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.2);
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #1B2A52;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  a.price-item-btn.price-btn-2 {
    margin-top: 50px;
  }
  .hide{
    display: none;
  }
  .price-item-btn i {
    margin-top: 2px;
  }
  .price-item p {
    color: #6A738B;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    margin-bottom: 15px;
  }
  .price-area{
    background-color: #fbf2e8;
  }
  a.price-item-btn.price-btn-4 {
    margin-top: 140px;
  }
  .testimonial-area {
    background: linear-gradient(180deg, rgba(242, 248, 253, 0) 0%, #f9f8f4 100%);
  }
  .testimonial-area .container {
    padding-right: 15px;
    padding-left: 15px;
    height: unset;
    border: none;
  }
  .brand-area {
    padding-top: 140px;
  }
  .brand-area .container {
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    height: unset;
  }
  .section-title {
    color: #1b2a52;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: -1.76px;
  }
  .brand-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
    align-items: center;
  }
  .brand-item {
    border-radius: 60px;
    background: #FFF;
    box-shadow: 0px 3px 14px 0px rgba(126, 147, 205, 0.1);
    height: 100px;
    width: 230px;
    display: grid;
    place-items: center;
    padding: 0 15px;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    cursor: pointer;
  }
  
  .h5_footer-area {
    background: #1B2A52;
  }
  .h5_footer-top {
    padding-top: 40px;
    border-radius: 0px 0px 30px 30px;
    background: #f9f8f4;
  }
  .h5_footer-area .container {
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    height: unset;
  }
  .pt-120 {
    padding-top: 120px;
  }
  .pb-80 {
    padding-bottom: 80px;
  }
  .h5_footer-logo img{
    width: 135px;
    margin-bottom: 15px;
  }
  .h5_footer-widget-text {
    color: #7F879E;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 23px;
  }
  .h5_footer-widget-social {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mb-35 {
    margin-bottom: 35px;
  }
  .h5_footer-widget-title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .h5_footer-widget ul {
    list-style: none;
    padding: 0;
  }
  .h5_footer-widget ul li:not(:last-child) {
    margin-bottom: 15px;
  }
  .h5_footer-widget form {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 20px;
  }
  .h5_footer-widget form input {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(27, 42, 82, 0.07);
    border: none;
    height: 56px;
    padding: 0 20px;
    color: #6A738B;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding-right: 158px;
  }
  .h5_footer-widget form button {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 8px;
    background: #27366d;
    height: 44px;
    border: none;
    padding: 0 32px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .h5_footer-widget ul li a {
    color: #7F879E;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    display: block;
    text-decoration: none;
  }
  .h5_footer-widget-social a {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 4px;
    border: 1px solid rgba(27, 42, 82, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7F879E;
    font-size: 14px;
    text-decoration: none;
  }
  .h5_footer-bottom {
    padding: 9px 0 10px;
  }
  .h5_footer-bottom .container {
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    height: unset;
  }
  .h5_footer-bottom-copyright p {
    margin-bottom: 0;
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
  }
  .h5_footer-bottom-menu ul {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: end;
  }
  .h5_footer-bottom-menu ul li {
    position: relative;
    z-index: 1;
    margin-right: 10px;
    padding-right: 11px;
  }
  .h5_footer-bottom-menu ul li a {
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    display: block;
    text-decoration: none;
  }
  .h5_footer-bottom-menu ul li:first-child::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 2px;
    height: 16px;
    background: #fff;
  }
  .row.maindash-nav.w-100 {
    padding: 0 100px;
  }
  .col-12.tapbar-text{
    padding: 0 140px 0 100px;
  }
  /* Dashboard */


  /* Login */
  .login-area {
    position: relative;
    z-index: 1;
}
.login-area .container {
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    height: unset;
}
.login-content {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 1px 6px 0.5px rgba(27, 42, 82, 0.14);
    padding: 70px 35px 70px;
}
.login-content-subtitle {
    color: #F15342;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-bottom: 6px;
    line-height: 20px;
}
.login-content-title {
    color: #1B2A52;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.03;
    letter-spacing: -0.9px;
    margin-bottom: 40px;
}
.login-form-item {
    position: relative;
    z-index: 1;
}
.login-form-item input {
    width: 100%;
    border: 1px solid rgba(27, 42, 82, 0.07);
    border-radius: 8px;
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
    color: #7F879E;
    font-size: 14px;
    font-weight: 400;
    padding-right: 50px;
}
.login-form-item i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #7F879E;
    font-size: 16px;
}
.mb-10 {
    margin-bottom: 10px;
}
.login-form-item button {
    width: 100%;
    border: none;
    border-radius: 10px;
    background: #1e2951;
    height: 46px;
    line-height: 46px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.login-condition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
}
.login-condition a {
    color: #F15342;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    display: block;
}
.login-content-link {
    text-align: center;
    margin-bottom: 48px;
}
.login-content-link p {
    color: #6A738B;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0;
}
.login-content-link p a {
    color: #F15342;
}
.login-break {
    position: relative;
    z-index: 1;
}
.login-break span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    font-size: 14px;
    line-height: 1;
    padding: 5px 11px;
    color: rgba(4, 0, 23, 0.7);
}
.login-content-account {
    display: flex;
    gap: 15px;
    padding-top: 55px;
}
.login-content-account-item {
    width: 100%;
    border-radius: 8px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-shape-1 {
    position: absolute;
    left: 14%;
    top: 22%;
}
.login-shape-2 {
    position: absolute;
    left: 47%;
    transform: translateX(-50%);
    bottom: 37%;
}
.login-shape-3 {
    position: absolute;
    right: 9%;
    bottom: 38%;
}
.pt-135{
    padding-top: 135px;
}
.pb-60{
    padding-bottom: 60px;
}
.mb-25{
    margin-bottom: 25px;
}
.login-area{
    background-color: #f8e9da;
    margin-bottom: 60px;
}
.login-break::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: rgba(4, 0, 23, 0.14);
}
  /* Login */

@media (max-width: 991px) {
  .banner-single {
    flex-wrap: wrap;
    gap: 70px;
  }
}
@media (max-width: 575px) {
  .banner-single {
    padding-top: 200px;
    padding-bottom: 160px;
  }
}
.banner-content {
  width: 720px;
  flex-shrink: 0;
}
@media (max-width: 1399px) {
  .banner-content {
    width: 610px;
  }
}
@media (max-width: 1199px) {
  .banner-content {
    width: 465px;
  }
}
@media (max-width: 991px) {
  .banner-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .banner-content-subtitle {
    font-size: 13px;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
  }
}
.banner-content-title {
  color: #1B2A52;
  font-size: 70px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -2.1px;
  margin-bottom: 20px;
}
@media (max-width: 1399px) {
  .banner-content-title {
    font-size: 60px;
  }
}
@media (max-width: 1199px) {
  .banner-content-title {
    font-size: 46px;
  }
}
@media (max-width: 991px) {
  .banner-content-title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .banner-content-title {
    font-size: 50px;
  }
  .tapbar-text{
    display: none;
  }
  .banner-single{
    padding-top: 50px;
  }
  .login-img img{
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .banner-content-title {
    font-size: 44px;
  }
}
@media (max-width: 480px) {
  .banner-content-title {
    font-size: 36px;
  }
}
@media (max-width: 991px) {
  .banner-img img {
      width: 100%;
  }
}
@media (max-width: 575px) {
  .feature-item {
      width: 100%;
  }
  .feature-shape-1 {
    display: none;
}
}
@media (max-width: 991px) {
  .feature-top {
      flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .banner-img {
      max-width: 100%;
      width: 100%;
  }
}
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}@media (max-width: 1024px) {
  .row.maindash-nav.w-100 {
    padding: 0 30px;
}
.col-12.tapbar-text {
  padding: 0 30px;
}
.dashlogin .btn{
  padding: 5px 10px !important;
}
}
.banner-img  img{
  max-width: 100%;
}
.h2_blog-area{
  background-color: #f7e8d8;
}
.h2_blog-area .container{
  height: unset;
  border: none;
  padding-right: 15px;
  padding-left: 15px;
}
.h3_faq-area {
  background: #f9f8f4;
}
.blog_details-widget {
  border-radius: 14px;
  background: #F8FAFF;
  padding: 30px 30px 35px;
}
.blog_details-widget-title {
  color: #1B2A52;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.6px;
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
}
.blog_details-widget-title::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: rgba(27, 42, 82, 0.07);
}
.blog_details-widget-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  content: "";
  background-color: #F15342;
}
.blog_details-widget-search {
  position: relative;
  z-index: 1;
}
.blog_details-widget-search input {
  width: 100%;
  border: 1px solid rgba(27, 42, 82, 0.07);
  height: 58px;
  padding: 0 20px;
  padding-right: 50px;
  background-color: transparent;
  border-radius: 10px;
  color: #7F879E;
  font-size: 14px;
  font-weight: 400;
}
.blog_details-widget-search button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 47px;
  height: 47px;
  border: none;
  border-radius: 10px;
  background: #2b356b;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
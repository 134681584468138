.App {
  text-align: center;
}

li.nav-li {
  margin: 20px 0px;
}

.signin h2 {
  text-align: center;
  margin-bottom: 40px;
}

.form-group label {
  margin: 10px 0px;
}

.aduser-login .form-control {
  padding: 22px;
  border-radius: 10px;
  margin: 25px 0;
}

.setting-form .form-group label {
  margin: 10px 0px;
  font-weight: 700;
}

.setting-form .form-control {
  padding: 10px;
  border-radius: 10px;
  margin: 4px;
}

.forgetpass {
  margin: 50px 10px 20px 10px;
}

.forgetpass a {
  font-size: 17px;
  letter-spacing: 1px;
  text-decoration: unset;
}

.aduser-login .signin-btn {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
}

.google-signin {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding: 10px;
}

.orline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 0px;
}

.logo-set {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  text-align: center;
  margin: 1% 1% 1% 20%;
}

.bloglist {
  display: flex;
  justify-content: space-between;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all{
  margin-top: 2px !important;
}
.createblog input {
  height: 45px;
  border-radius: 10px;
}

.initialmsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.adupgradenow {
  background-image: url('../../public/assets/navback.png');
  border-radius: 20px;
  color: #fff;
  margin: 25px;
  padding: 25px 40px;
}

.addicon button {
  border: none;
  background: none;
}

.sizefix{
  min-height: 52rem;
}

.dash-widget-icon {
  display: inline-flex;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
  border: 3px solid;
  border-radius: 50px;
  padding: 12px;
}

.progress{
  height: 10px !important;
}

.outer{
  border: 1px solid #D0D1D2;
  border-radius: 10px;
}

.adupgradenow button {
  margin-top: 80px;
  width: 100%;
}

.headtoggle {
  margin: 10px !important;
}

.addicon i {
  font-size: 50px;
  background-color: #D9D9D9;
  color: #A1A1AA;
  border-radius: 57%;
  padding: 25px;
}

.selectbtns{
  text-align: end ;
}

.bloglist button {
  border: none;
  background: none;
}

ul {
  list-style-type: none;
}

.serchblog {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.serchblog input {
  margin: 0 20px;
  border-radius: 10px;
}

.adContainer {
  background-color: #ffffff;
  padding: 10px 10px 10px 20px;
  min-height: 265px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.category-tab {
  border: 1px solid #e9ecef;
  text-align: center;
}

.adminheader-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  margin-right: 25px;
  border-bottom: 1px solid #ebebeb;
}

.main-sec {
  padding: 0 50px;
}

.css-heg063-MuiTabs-flexContainer,
.css-k008qs {
  justify-content: space-between;
}

.text-container {
  padding: 10px;
  min-height: 5rem;
  margin-bottom: 20px;
  border: 1px solid #aba2a2;
  border-radius: 10px;
}

.profilecontainer{
  border: 1px solid #000;
  border-radius: 10px;
}

.userprofile {
  border-radius: 10px;
  background-color: #27366d;
  padding: 25px;
  color: #fff;
}

.invoiceicn {
  display: flex;
  justify-content: space-between;
  width: 25%;
  padding: 0 25px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

.invoiceicn svg {
  margin: 0 !important;
}

.userinvoice {
  border: 1px solid #cecdcd;
  border-radius: 10px;
  padding: 25px;
}

.invoice-container {
  color: #ffffff;
  background-image: linear-gradient(to right, #62587a, #7a6c9a);
  border-radius: 10px;
  padding: 85px 50px;
  margin: 20px 80px;
}

.invoice-containers {
  display: flex;
  justify-content: space-evenly;
}

.invoice-blue {
  display: flex;
  margin: 20px;
  color: #ffffff;
  border-radius: 10px;
  background: #27366d;
  padding: 15px 30px;
  width: 50%;
  align-items: center;
  text-align: center;
}

.icn-box {
  padding: 15px 20px;
  margin: 10px 25px;
  border: 1px solid;
  border-radius: 10px;
}

.invoice-green {
  margin: 20px;
  padding: 15px 30px;
  display: flex;
  color: #ffffff;
  border-radius: 10px;
  background: #0cc974;
  width: 50%;
  align-items: center;
  text-align: center;
}

.invoice-purple {
  display: flex;
  padding: 15px 30px;
  margin: 20px;
  color: #ffffff;
  border-radius: 10px;
  width: 50%;
  background: #af4dd7;
  align-items: center;
  text-align: center;
}

.invoice-red {
  padding: 15px 30px;
  display: flex;
  margin: 20px;
  color: #ffffff;
  width: 50%;
  border-radius: 10px;
  background: #ff545f;
  align-items: center;
  text-align: center;
}

.invoice-innrcontainer {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.invoice-innrcontainers {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.invoice-innrcontainersss {
  border: 1px solid #cecdcd;
  border-radius: 10px;
}

.invoiceplan {
  display: flex;
  justify-content: space-between;
  padding: 7px 40px;
  background: #cecdcd;
  border-radius: 10px;
}

.invoiceplandata {
  display: flex;
  justify-content: space-between;
  padding: 7px 40px;
  border-radius: 10px;
}

.outer-space button {
  padding: 8px;
  border-radius: 10px;
  color: #27366d;
  border: 2px solid #27366d;
}

.Adheadname {
  margin: 0px 100px 0px 15px;
  display: flex;
  align-items: center;
}

.Adheadname h4 {
  font-weight: 700;
  margin: 0;
}

.Hide-Sources {
  display: none;
}

.account-setting input {
  margin: 10px 0 30px 0px;
  padding: 20px;
  border-radius: 10px;
}

.account-setting label {
  margin: 0px;
  font-weight: 700;
}

.table-bordr tr {
  border-bottom: 1px solid #aba2a2;
}

.table-bordr th,
.table-bordr td {
  padding: 10px 30px;
  /* width: 5%; */
}

#myColor {
  width: 10%;
}

.input-container {
  border: 1px solid #aba2a2;
  border-radius: 10px;
}
.addmore {
  text-align: end;
  margin: 20px 40px;
}

.receivemail {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  /* border-top: 1px solid #dee2e6; */
  border-bottom: 1px solid #dee2e6;
}

.notification input {
  width: 70%;
  margin: 0 15px 0 0;
  border-radius: 10px;
}

.notification {
  display: flex;
  margin: 25px 0
}

.urldatafield input {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  width: 70%;
  margin: 0 45px;
}

.gpt p {
  margin: 0 0 0 10px;
  padding: 10px;
  border-radius: 10px;
  color: #795be3;
  background: #eae6fe;
}

/* SWITCH */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e21313;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #88ff00;
}

input:focus+.slider {
  box-shadow: 0 0 1px #88ff00;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* SWITCH */
@media only screen and (max-width: 768px) {
  .adminvav {
    display: none;
  }

  button.btn.btn-primary.px-4.py-2 {
    padding: 4px !important;
    margin: 3px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 425px) {
  .mobcheck {
    padding-left: 20px !important;
  }

  button.btn.btn-danger,
  button.btn.btn-primary.mb-2.mb-md-0.me-md-4 {
    padding: 4px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1440px) {

  .invoice-blue,
  .invoice-green,
  .invoice-purple,
  .invoice-red {
    padding: 15px;
    margin: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .invoice-container {
    padding: 80px 30px;
    margin: 20px 50px;
  }

  .invoice-blue,
  .invoice-green,
  .invoice-purple,
  .invoice-red {
    padding: 15px;
    margin: 10px;
  }

  .invoice-blue .icn-box,
  .invoice-green .icn-box,
  .invoice-purple .icn-box,
  .invoice-red .icn-box {
    padding: 15px;
    margin: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .invoice-container {
    padding: 70px 15px;
    margin: 10px;
  }

  .adupgradenow,
  .copyright {
    display: none;
  }

  .invoice-blue,
  .invoice-green,
  .invoice-purple,
  .invoice-red {
    padding: 12px;
    margin: 5px;
  }

  .invoice-blue .icn-box,
  .invoice-green .icn-box,
  .invoice-purple .icn-box,
  .invoice-red .icn-box {
    padding: 5px;
    margin: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .invoice-container {
    padding: 20px 15px;
    margin: 1px;
  }

  .headtoggle {
    margin: 0 !important;
  }

  .main-sec.mt-5 {
    margin-top: 10px !important;
  }

  .serchblog.p-4 {
    padding: 10px !important;
  }

  .serchblog h5.m-0 {
    width: unset !important;
  }

  .invoice-container p {
    font-size: 15px;
  }

  .invoice-container h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .invoice-container span {
    font-size: 14px;
  }

  /* button.btn.btn-primary,
  button.btn.btn-secondary {
    padding: 4px !important;
    margin: 2px !important;
    font-size: 12px;
  } */

  .icn-box {
    padding: 2px;
    margin: 2px;
    font-size: 10px;
  }

  .invoice-blue,
  .invoice-green,
  .invoice-purple,
  .invoice-red {
    display: block;
    padding: 5px;
    margin: 2px;
  }

  .invoice-blue p,
  .invoice-blue h2,
  .invoice-green p,
  .invoice-green h2,
  .invoice-purple p,
  .invoice-purple h2,
  .invoice-red p,
  .invoice-red h2 {
    margin: 0;
    font-size: 12px;
  }
}